<template>
  <v-container class="my-4 py-6">
    <h2>
      Empleados
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="showForm = !showForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        v-if="showForm"
        class="px-4 py-4 mx-auto"
        max-width="800"
      >
        <p class="text-primary">
          {{ currentTitle }}
        </p>
        <v-card-text>
          <usuario-simple 
            :usuario="usuario_empleado"
            :showSpan="false"
            md="4" />
          <contacto-simple 
            :infoContacto="contacto_usuario_empleado" 
            md="4" />
          <v-col>
            <v-autocomplete
              v-model="distributorId"
              label="Distribuidor"
              :items="distribuidores"
              item-text="nombre_distribuidor"
              item-value="id"
              dense
              solo
            />
          </v-col>  
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="createEmployee"
          >
            Crear
          </v-btn>
          <v-btn
            color="primary"
            @click="clearAllObjects"
          >
            Limpiar
          </v-btn>
        </v-card-actions>
      </v-card>
     
    </v-row>
    <v-row
      class="my-4"
      justify="center"
    >
      <v-col>
        <div v-if="employees.length">
          <paginate
            name="products"
            :list="employees"
            :per="perPage"
            class="pl-0"
          >
            <v-row justify="center">
              <v-col
                v-for="employee in paginated('products')" 
                :key="employee.id"
                md="4"
              >
                <v-card
                  max-width="300"
                  class="mx-4 my-4"
                >
                  <v-card-text>
                    <p class="display-1 text--primary">
                      {{ employee.usuario.username }}
                    </p>
                    <div>
                      Nombre: {{ fullName(employee) }}<br>
                      Correo: {{ employee.usuario.email }}<br>
                      Distribuidor: {{ getAsignedDistributorName(employee.usuario.id) }}
                    </div>
                  </v-card-text>
                  <!-- <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                    >
                      Editar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="deleteEmployee(employee.id)"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </paginate>
          <paginate-links
            for="products"
            :async="true"
            :limit="5"
            :hide-single-page="true"
            :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
          >
          </paginate-links>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import store from '@/store'

export default {
  data() {
    return {
      step: 1,
      employees: [],
      distribuidores: [],
      paginate: ['products'],
      perPage: 3,
      showForm: false,
      distributorId: null,
      usuario_empleado: {
        username: null,
        password: null,
        password_confirmation: null
      },
      contacto_usuario_empleado: {
        nombre: null,
        apellidoPaterno: null,
        correo: null,
      },
      empleado: {
        nombreUsuario: null,
        password: null,
        correo: null,
        nombre: null,
        apellidoPaterno: null
      },
    }
  },
  components: {
    UsuarioSimple: () => import('@/components/utils/SimpleForms/UsuarioSimple'),
    ContactoSimple: () => import('@/components/utils/SimpleForms/ContactoSimple'),
  },
  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Empleado'
        case 2: return 'Datos personales de empleado'
        default: return 'Empleado creado'
      }
    },
    nextAction () {
      return this.step === 2 ? 'Crear' : 'Siguiente' 
    },
  },
  methods: {
    async createEmployee() {
      store.commit('setLoading', true)
      this.preparingDataToSend()
      const response = await ApiAuth.post('/personas/crear-empleado', this.empleado)
      const responseData = await response.data
      // console.log("Response data: ", responseData);

      if (responseData.id_empleado) {
        // Se creo el empleado correctamente
        const responseDistributor = await ApiAuth.post('/personas/agregar-empleado-a-distribuidor', {
          id_empleado: responseData.id_empleado,
          id_distribuidor: this.distributorId
        })
        const responseDataDistributor = await responseDistributor.data
        // console.log("Response data distributor: ", responseDataDistributor);
        if (responseDataDistributor.mensaje.includes('empleado agregado')) {
          // Se asigno correctamente el empleado al distribuidor
        } else {
          // Ocurrio un error y no se pudo asignar el empleado
          // deberia mandar una alerta
        }
        this.clearAllObjects()
      } else {
        // Algo ocurrio y no se creo el empleado
        // deberia mandar una alerta
      }
      store.commit('setLoading', false)
    },
    deleteEmployee(idEmployee) {
      const response = ApiAuth.delete(`/personas/api/empleado/${idEmployee}/`)
      const responseData = response.data
      // console.log("Response delete: ", responseData);
      this.getEmployees()
    },
    preparingDataToSend() {
      this.empleado = {...this.contacto_usuario_empleado}
      this.empleado.nombreUsuario = this.usuario_empleado.username
      this.empleado.password = this.usuario_empleado.password
    },
    async getEmployees() {
      const response = await ApiAuth.get('/personas/api/empleado/')
      const responseData = await response.data
      // console.log("responseData employees: ", responseData);
      this.employees = responseData
    },
    async getDistribuidores() {
      const response = await ApiAuth.get("/personas/api/distribuidor/")
      const responseData = await response.data
      // console.log("Response data distribuidores: ", responseData);
      this.distribuidores = responseData
    },
    fullName(employee) {
      return `${employee.usuario.first_name} ${employee.usuario.last_name}`
    },
    getAsignedDistributorName(userId) {
      const asignedDistributors = this.distribuidores.filter(dis => {
        return dis.usuarios_autorizados.some(user => user.id === userId)
      })
      // console.log("asignedDistributors: ", asignedDistributors);
      if (asignedDistributors.length) {
        const names = []
        asignedDistributors.forEach(dis => {
          names.push(dis.nombre_distribuidor)
        })
        return names.join(", ")
      }
      return "Sin asignar"
    },
    clearObject(object) {
      Object.keys(object).forEach(key => object[key] = null)
    },
    clearAllObjects() {
      this.clearObject(this.usuario_empleado)
      this.clearObject(this.contacto_usuario_empleado)
      this.clearObject(this.empleado)
    }
  },
  created() {
    this.getEmployees()
    this.getDistribuidores()
  }
}
</script>

<style>

</style>